<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">VENTAS</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        ><strong>NUEVA VENTA: </strong>{{ fechaActual(fecha_venta) }}</span
      >
    </li>
  </ul>
  <div v-if="cargandoComponente">
    <div class="grid" v-if="permitirVenta">
      <div class="col-12 md:col-8">
        <div class="card">
          <div class="p-fluid formgrid grid p-fondo">
            <div class="field col-12 md:col-5 mt-2">
              <label for="cliente"><strong>SELECCIONE CLIENTE:</strong></label>
              <div class="p-inputgroup">
                <AutoComplete
                  :dropdown="true"
                  field="nombre"
                  v-model="clienteSelect"
                  :suggestions="clientes"
                  @complete="buscarCliente($event)"
                  placeholder="Buscar Cliente"
                  v-tooltip.top="'Buscar Todos los Clientes'"
                  class="mr-1"
                >
                  <template #item="slotProps">
                    <div>
                      <strong>{{ slotProps.item.nombre }}</strong
                      ><span><strong> - Nit:</strong></span>
                      {{ slotProps.item.ci_nit }}
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-user"
                  class="p-button-rounded p-button-info p-button-outlined"
                  v-tooltip.top="'Nuevo Cliente'"
                  @click="activarCliente"
                />
              </div>
            </div>
            <div class="field col-12 md:col-3 mt-2">
              <label for="cliente"><strong>CI/NIT:</strong></label>
              <div class="p-inputgroup">
                <InputText
                  v-tooltip.top="'Nit a Facturar'"
                  v-model="nit"
                  placeholder="0"
                  class="mr-1"
                  :disabled="!facturar"
                  @keyup.enter="buscarClientePorNit"
                />
                <!-- @keyup.enter="buscarClientePorNit" -->
                <!-- :disabled="!facturar" -->
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-search"
                  class="p-button-rounded p-button-info p-button-outlined"
                  v-tooltip.top="'Buscar NIT'"
                  @click="buscarClientePorNit"
                  :disabled="!facturar"
                />
              </div>
            </div>
            <div class="field col-12 md:col-4 mt-2">
              <label for="cliente"><strong>RAZ&Oacute;N SOCIAL:</strong></label>
              <div class="p-inputgroup">
                <Textarea v-model="razon_social" rows="1" readonly autoResize />
              </div>
            </div>
          </div>
          <div
            class="p-fluid formgrid grid mt-4"
            v-if="'Venta ConMedico' in auth.user.permissions"
          >
            <div class="field col-12 md:col-6">
              <div class="p-inputgroup">
                <h5><strong>CON MECÁNICO:</strong></h5>
                <Checkbox
                  v-model="medico_referencia"
                  value="medico_referencia"
                  class="ml-1 mr-2"
                  v-tooltip.top="'Médico Referencia'"
                />
                <Dropdown
                  v-if="medico_referencia == 'medico_referencia'"
                  v-model="medicoSelected"
                  :options="medicos"
                  placeholder="Seleccione..."
                  optionLabel="nombre_completo"
                  optionValue="id"
                  :filter="true"
                  class="mr-1"
                />
                <Button
                  v-if="
                    medico_referencia == 'medico_referencia' &&
                    'Medico Crear' in auth.user.permissions
                  "
                  icon="pi pi-id-card"
                  class="p-button-rounded p-button-info p-button-outlined"
                  v-tooltip.top="'Nuevo Médico'"
                  @click="activarMedico"
                />
              </div>
            </div>
            <div
              class="field col-12 md:col-6"
              v-if="'Venta VenderPaquete' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <h5><strong>PAQUETES: </strong></h5>

                <Checkbox
                  v-model="vender_paquete"
                  value="vender_paquete"
                  class="ml-4 mr-2"
                  v-tooltip.top="'Vender Paquete'"
                />
                <Dropdown
                  v-if="vender_paquete == 'vender_paquete'"
                  v-model="paqueteSelected"
                  :options="paqueteproductos"
                  placeholder="Seleccione..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />

                <Button
                  v-if="vender_paquete == 'vender_paquete'"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-info p-button-outlined ml-2"
                  v-tooltip.top="'Agregar Paquete al Detalle'"
                  @click="agregarProductosPaqueteAlDetalle"
                />
              </div>
            </div>
          </div>

          <div class="p-fluid formgrid grid mt-2">
            <!-- <div class="field col-12 md:col-2">
              <label for=""><strong>C&Oacute;DIGO</strong></label>
              <div class="p-imputgroup">
                <AutoComplete
                  :dropdown="true"
                  field="codigo_barra"
                  v-model="codProdSelected"
                  :suggestions="codProdListadoFiltrado"
                  v-tooltip.top="'Buscar Producto por Código'"
                  @complete="buscarCodProducto($event)"
                  placeholder="Codígo..."
                  @keyup.enter="buscarCodProducto($event)"
                />
              </div>
            </div> -->
            <div class="field col-12 md:col-12">
              <label for="productos"
                ><strong
                  >BUSCAR PRODUCTO: DESCRIPCI&Oacute;N | COD. INT. | COD. ORIGEN
                  | COD. EQUIV.
                </strong></label
              >
              <Panel
                toggleable
                :collapsed="masFiltrosBusqueda"
                class="shadow-2"
                @update:collapsed="actualizarPanelMasFiltros"
              >
                <template #header>
                  <div class="p-inputgroup">
                    <AutoComplete
                      field="descripcion"
                      placeholder="Buscar Producto"
                      v-tooltip.top="'Buscar Producto por Nombre'"
                      autofocus
                      v-model="productoSelecionado"
                      :loading="true"
                      :delay="300"
                      :suggestions="productosListadoFiltrado"
                      @keyup.enter="buscarProducto($event)"
                    >
                      <!-- @complete="buscarProducto($event)" -->
                      <template #item="slotProps">
                        <div>
                          <strong>{{ slotProps.item.descripcion }} **</strong>
                          {{ slotProps.item.fabrica_nombre }}
                          <strong> **</strong>
                          <!-- <span
                            style="background-color: aqua"
                            v-if="
                              busqueda_caso_uso == true &&
                              slotProps.item.caso_uso != null &&
                              slotProps.item.caso_uso != ''
                            "
                          >
                            {{ slotProps.item.caso_uso }} <strong>**</strong>
                          </span> -->
                          <span
                            :class="
                              stockClass(
                                slotProps.item.stock.length > 0
                                  ? sinDecimal(slotProps.item.stock[0].cantidad)
                                  : 0
                              )
                            "
                            ><strong>{{
                              slotProps.item.stock.length > 0
                                ? sinDecimal(slotProps.item.stock[0].cantidad)
                                : 0
                            }}</strong></span
                          >
                          <span><strong> ** </strong></span>
                          <!-- style="background-color: chartreuse;" -->
                          <strong
                            >BS-{{
                              slotProps.item.precio_sugerido > 0
                                ? slotProps.item.precio_sugerido
                                : "Sin Precio"
                            }}</strong
                          >
                        </div>
                      </template>
                    </AutoComplete>
                    <Button
                      icon="pi pi-search"
                      class="p-button-rounded p-button-info p-button-outlined ml-2"
                      v-tooltip.top="'Buscar Producto por Nombre'"
                      @click="buscarProducto($event)"
                    ></Button>
                    <!-- <Checkbox
                    v-model="busqueda_caso_uso"
                    :binary="true"
                    class="mt-1 ml-2"
                    v-tooltip.top="'Buscar por Principio Activo'"
                  /><Badge class="bg-info text-white mt-1 ml-1">P.ACTIVO</Badge> -->
                  </div>
                </template>

                <div class="grid p-2">
                  <div class="field col-12 md:col-12">
                    <div class="p-inputgroup">
                      <label for="productos" class="mt-2 mr-2"
                        ><strong>GRUPO: </strong></label
                      >
                      <Dropdown
                        class="mr-2"
                        v-model="grupoSelected"
                        :options="grupos"
                        placeholder="Seleccionar Grupo..."
                        optionLabel="nombre"
                        optionValue="id"
                        :filter="true"
                      />
                      <label for="laboratorios" class="mt-2 mr-2"
                        ><strong>MARCAS: </strong></label
                      >
                      <Dropdown
                        class="mr-2"
                        v-model="laboratorioSelected"
                        :options="laboratorios"
                        placeholder="Seleccionar Marca..."
                        optionLabel="nombre"
                        optionValue="id"
                        :filter="true"
                      />
                      <Button
                        icon="pi pi-replay"
                        class="p-button-rounded p-button-info p-button-outlined ml-2"
                        v-tooltip.top="'Limpiar Filtros'"
                        @click="limpiarFiltrosBusquedaProducto"
                      />
                    </div>
                    <!--  <label for="productos"><strong>GRUPO</strong></label>
                      <Dropdown
                        v-model="grupoSelected"
                        :options="grupos"
                        placeholder="Seleccione..."
                        optionLabel="nombre"
                        optionValue="id"
                        :filter="true"
                      />
                    </div>
                   
                    <div class="field col-12 col:md-2">
                      
                    <Button
                      icon="pi pi-replay"
                      class="p-button-rounded p-button-info p-button-outlined ml-2"
                      v-tooltip.top="'Limpiar Filtros'"
                      @click="limpiarFiltrosBusquedaProducto"
                    /> -->
                  </div>
                  <!-- <div class="col-5">
                    <div class="p-input-group">
                      <label for="productos"
                        ><strong>PROCEDENCIA</strong></label
                      >
                      <Dropdown
                        v-model="procedenciaSelected"
                        :options="procedencias"
                        placeholder="Seleccione..."
                        optionLabel="nombre"
                        optionValue="id"
                        :filter="true"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-1">
                    <Button
                      icon="pi pi-replay"
                      class="p-button-rounded p-button-info p-button-outlined ml-2"
                      v-tooltip.top="'Limpiar Filtros'"
                      @click="limpiarFiltrosBusquedaProducto"
                    />
                  </div> -->
                  <div class="col-12 flex flex-wrap gap-3 pt-3">
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="filtrosRadio"
                        inputId="codigo_interno"
                        name="codigo_interno"
                        value="codigo_interno"
                      />
                      <label for="ingredient1" class="ml-2">CÓD. INTERNO</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="filtrosRadio"
                        inputId="codigo_origen"
                        name="codigo_origen"
                        value="codigo_origen"
                      />
                      <label for="codigo_origen" class="ml-2"
                        >CÓD. ORIGEN</label
                      >
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="filtrosRadio"
                        inputId="codigo_equivalente"
                        name="codigo_equivalente"
                        value="codigo_equivalente"
                      />
                      <label for="codigo_equivalente" class="ml-2"
                        >CÓD. EQUIV</label
                      >
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="filtrosRadio"
                        inputId="medidas"
                        name="medidas"
                        value="medidas"
                      />
                      <label for="medidas" class="ml-2">MEDIDAS</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="filtrosRadio"
                        inputId="caso_uso"
                        name="caso_uso"
                        value="caso_uso"
                      />
                      <label for="caso_uso" class="ml-2">OBSERVACIONES</label>
                    </div>
                  </div>
                </div>
              </Panel>

              <!--  <div class="p-inputgroup">
                <MultiSelect
                  filter
                  v-model="productosSelecionado"
                  :options="productosListadoFiltrado"
                  placeholder="Buscar Producto..."
                  class="w-full md:w-20rem"
                  emptyMessage="No se encontraron resultados"
                  @filter="buscarProducto($event)"
                  @change="change_multiselect($event)"
                >
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>{{ slotProps.option.descripcion }}</div>
                    </div>
                  </template>
                </MultiSelect>
              </div> -->
            </div>
            <!--  <div class="field col-12 md:col-12">
              <DataTable
                ref="dt_stock_sucursales"
                :value="stockSucursales"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                :rowhover="true"
              >
                <template #empty> Debe seleccionar un producto </template>
                <Column field="nombre_sucursal" header="Sucursal">
                  {{ data.nombre_sucursal }}
                </Column>
                <Column class="text-right" field="cantidad" header="En Stock">
                  <template #body="{ data }">
                    <div :class="stockClass(data)">
                      {{ convertirNumeroGermanicFormat(data.cantidad) }}
                    </div>
                  </template>
                </Column>

                <Column
                  class="text-right"
                  field="ultimo_precio"
                  header="Precio Venta"
                >
                  <template #body="slotProps">
                    {{
                      convertirNumeroGermanicFormat(
                        slotProps.data.ultimo_precio
                      )
                    }}
                  </template>
                </Column>
                <Column header="Acciones">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-info mr-1"
                      @click="agregarDetalleVenta(slotProps.data)"
                      v-tooltip.top="'Agregar Detalle'"
                      :disabled="slotProps.data.cantidad <= 0"
                    />
                  </template>
                </Column>
              </DataTable>
            </div> -->
          </div>
          <div v-if="proforma" class="p-fluid formgrid grid">
            <div class="field col-12 md:col-2 mt-2">
              <Button
                v-tooltip.top="'Agregar Producto'"
                @click="agregarProductoDinamico()"
                icon="pi pi-plus"
                class="p-button-rounded p-button-info p-button-outlined"
              ></Button>
            </div>
            <div class="field col-12 md:col-3 mt-2">
              <label for="nro_dias_proforma" class="ml-2">Dias validos :</label>
              <InputText v-model="nro_dias_proforma" class="text-right" />
            </div>
          </div>
          <DataTable
            ref="dt_venta_detalle"
            class="p-datatable-sm"
            dataKey="id"
            :value="productosVentas"
            responsiveLayout="scroll"
            scrollHeight="400px"
            editMode="cell"
            @cell-edit-complete="guardandoCambiosCelda"
            :rowhover="true"
            stripedRows
            showGridlines
          >
            <template #empty>
              <span
                class="flex align-items-center justify-content-center p-invalid"
                >Busca Productos para agregar al Detalle!</span
              ></template
            >
            <Column header="#">
              <template #body="slotProps">
                {{ 1 + slotProps.index }}
              </template>
            </Column>
            <Column
              field="nombre_producto"
              header="DESCRIPCIÓN/PRODUCTO"
              style="font-weight: bold"
            >
              <template #body="{ data }">
                {{ data.nombre_producto }}
              </template>
              <template #editor="{ data, field }">
                <InputText v-model="data[field]" class="p-inputtext-sm" autofocus/>
              </template>
            </Column>
            <Column class="text-right strong" field="cantidad" header="STOCK">
              <template #body="{ data }">
                <span :class="claseEstado(data)">
                  <strong> {{ sinDecimal(data.cantidad) }}</strong>
                </span>
              </template>
            </Column>
            <Column
              class="text-right strong"
              field="cantidad_venta"
              header="CANTIDAD"
            >
              <template #body="slotProps">
                {{ slotProps.data.cantidad_venta }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="flex-row-reverse"
                  locale="de-DE"
                  class="p-inputtext-sm"
                  :min="1"
                  autofocus
                />
              </template>
            </Column>
            <Column
              class="text-right strong"
              field="ultimo_precio"
              header="PRECIO"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.ultimo_precio)
                }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="flex-row-reverse"
                  locale="de-DE"
                  class="p-inputtext-sm"
                  :min="1"
                  autofocus
                />
              </template>
            </Column>
            <Column
              v-if="aplica_descuento == 'aplica_descuento'"
              class="flex-row-reverse strong bg-green-200"
              field="descuento_neto"
              header="DESC."
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.descuento_neto)
                }}
              </template>
              <template #editor="slotProps">
                <InputNumber
                  :inputClass="'text-right'"
                  :min="0"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                  v-model="slotProps.data.descuento_neto"
                />
              </template>
            </Column>
            <Column class="text-right" field="total" header="TOTAL">
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.ultimo_precio *
                      slotProps.data.cantidad_venta -
                      slotProps.data.descuento_neto
                  )
                }}
              </template>
            </Column>
            <!-- <Column
                v-if="aplica_descuento == 'aplica_descuento'"
                class="text-right strong"
                field="descuento_porcentaje"
                header="Desct. %"
              >
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.descuento_porcentaje
                    ) + "%"
                  }}
                </template>
                <template #editor="slotProps">
                  <InputNumber
                    :inputClass="'text-right'"
                    :min="0"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    locale="de-DE"
                    v-model="slotProps.data.descuento_porcentaje"
                  />
                </template>
              </Column> -->
            <Column>
              <template #header>
                <i class="pi pi-check"></i>
              </template>
              <template #body="slotProps">
                <Checkbox
                  v-model="slotProps.data.entregado"
                  value="entregado"
                  :disabled="slotProps.data.cantidad_venta == 0"
                  v-tooltip.top="'Entregado'"
                />
              </template>
            </Column>
            <Column v-if="proforma" field="nro_dias_importacion">
              <template #header>
                <i class="pi pi-calendar"></i>
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="flex-row-reverse"
                  locale="de-DE"
                  class="p-inputtext-sm"
                  :min="1"
                  autofocus
                />
              </template>
            </Column>
            <Column class="strong" style="min-width: 20px">
              <template #header>
                <i class="pi pi-trash"></i>
              </template>
              <template #body="slotProps">
                <Button
                  @click="quitarProductoDetalle(slotProps)"
                  v-tooltip.top="'Quitar Producto'"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="card">
          <h4><strong>TOTALES DE VENTAS:</strong></h4>
          <div class="p-fluid formgrid grid">
            <table class="field col-12 md:col-12">
              <tr v-if="grupos_id_selected != '' && grupos_id_selected != null">
                <td>
                  <strong>DESC. ASIGNADOS:</strong>
                </td>
                <td
                  class="text-right"
                  style="
                    background-color: #fbc02d;
                    border-radius: 5px;
                    padding: 1px;
                    font-weight: bold;
                  "
                >
                  {{ grupos_id_selected }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>SUB TOTAL:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(subtotal) }} Bs.
                </td>
              </tr>
              <tr v-if="aplica_descuento == 'aplica_descuento'">
                <td>
                  <strong>DESCUENTO:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(descuento) }} Bs.
                </td>
              </tr>
              <tr>
                <td>
                  <h4><strong>TOTAL VENTA:</strong></h4>
                </td>
                <td class="text-right">
                  <h2>{{ convertirNumeroGermanicFormat(total_venta) }} Bs.</h2>
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>A CUENTA:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(a_cuenta + monto_pago) }} Bs.
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>SALDO:</strong>
                </td>
                <td class="text-right">
                  {{
                    convertirNumeroGermanicFormat(
                      total_venta - a_cuenta - monto_pago
                    )
                  }}
                  Bs.
                </td>
              </tr>
            </table>
            <div class="field col-12 md:col-12" v-if="facturar">
              <label for="actividad_economica"
                ><strong>ACTIVIDAD ECONOMICA:</strong></label
              >
              <div class="p-inputgroup">
                <Dropdown
                  id="actividad_economica"
                  v-model="actividad_economica_selected"
                  :options="actividades_economicas"
                  option-label="descripcion"
                  option-value="codigo"
                  placeholder="Seleccione una actividad economica"
                  @change="cambiarActividadEconomica"
                />
                <!-- <Button
                  icon="pi pi-refresh"
                  @click="cambiarActividadEconomica"
                  class="p-button-rounded p-button-info p-button-text"
                  v-tooltip.top="'Cambiar Actividad Economica'"
                /> -->
              </div>
            </div>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <Checkbox
                  v-if="permitir_facturar"
                  class="mr-1"
                  value="facturar"
                  v-model="facturar"
                  :binary="true"
                  v-tooltip.top="'Facturar'"
                  :disabled="false"
                /><span
                  v-if="permitir_facturar"
                  class="p-tag p-tag-rounded p-tag-danger"
                  >FACTURAR</span
                >
                <Checkbox
                  v-if="'Venta Descuentos' in auth.user.permissions"
                  :class="permitir_facturar ? 'ml-3 mr-1' : 'mr-1'"
                  value="aplica_descuento"
                  :disabled="descuento > 0 || total_venta == 0"
                  v-model="aplica_descuento"
                  v-tooltip.top="'Aplicar Descuento'"
                />
                <span
                  class="p-tag p-tag-rounded p-tag-warning"
                  v-if="'Venta Descuentos' in auth.user.permissions"
                  >DESCUENTO</span
                >
                <Checkbox
                  class="ml-3 mr-1"
                  value="observacion_check"
                  id="binary"
                  v-tooltip.top="'Observación'"
                  v-model="observacion_check"
                />
                <span class="p-tag p-tag-rounded p-tag-info">OBSERVACIÓN</span>
              </div>
            </div>
            <div
              class="field col-12 md:col-12"
              v-if="observacion_check == 'observacion_check'"
            >
              <Textarea
                v-model="observacion"
                rows="3"
                autoResize
                placeholder="Glosa/observación"
              />
            </div>
            <template v-if="aplica_descuento == 'aplica_descuento'">
              <table class="field col-12 md:col-12">
                <tr>
                  <td>
                    <strong>DESCUENTO NETO:</strong>
                  </td>
                  <td class="text-right">
                    <InputNumber
                      v-model="descuento"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="subtotal"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento neto"
                      @keyup="calcular_agregar_des_total_venta"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>DESCUENTO %:</strong>
                  </td>
                  <td class="text-right strong">
                    <InputNumber
                      v-model="desc_venta_porcentaje"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="(subtotal * 100) / total_venta"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento %"
                      @keyup="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                </tr>
              </table>
            </template>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <Checkbox
                  class="mr-1"
                  value="a_credito_check"
                  v-model="a_credito_check"
                  v-tooltip.top="'Seleccionar tipo de pago'"
                  :disabled="tipo_pagoSelect != 1 || total_venta == 0"
                /><span class="p-tag p-tag-rounded p-tag-danger"
                  >A CRÉDITO</span
                >
                <Checkbox
                  class="ml-3 mr-1"
                  value="proforma"
                  :binary="true"
                  v-tooltip.top="'Crear como proforma'"
                  v-model="proforma"
                />
                <span class="p-tag p-tag-rounded p-tag-warning">PROFORMA</span>
                <Checkbox
                  class="ml-3 mr-1"
                  value="metodo_pago_check"
                  v-model="metodo_pago_check"
                  :disabled="
                    metodo_pagoSelect != 1 ||
                    a_credito_check == 'a_credito_check' ||
                    total_venta == 0 ||
                    facturar == true
                      ? true
                      : false
                  "
                  v-tooltip.top="'Seleccionar Método de Pago'"
                /><span class="p-tag p-tag-rounded p-tag-info"
                  >CON TARJETA</span
                >
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <table>
                <tr v-if="a_credito_check == 'a_credito_check'">
                  <td>
                    <strong>TIPO DE PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      id="tipo_pago"
                      v-model="tipo_pagoSelect"
                      :options="tipo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>D&Iacute;AS CR&Eacute;DITO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="dias_credito"
                      :min="1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      showButtons
                    />
                  </td>
                </tr>
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>A CUENTA EFECTIVO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="a_cuenta"
                      :min="0"
                      :max="total_venta - monto_pago - 1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :minFractionDigits="2"
                      :useGrouping="false"
                      showButtons
                      placeholder="a cuenta efectivo"
                      :disabled="metodo_pagoSelect != 1"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pago_check == 'metodo_pago_check'">
                  <td>
                    <strong>M&Eacute;TODO PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      id="metodo_pago"
                      v-model="metodo_pagoSelect"
                      :options="metodo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect != 1">
                  <td>
                    <strong>MONTO PAGO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="monto_pago"
                      :min="0"
                      :max="total_venta - a_cuenta"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      :minFractionDigits="2"
                      showButtons
                      placeholder="monto pago transferencias"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="field col-12 md:col-6 hidden">
              <label for=""><strong>Cotizaci&oacute;n</strong></label>
              <Checkbox class="ml-3" value="cotizar" v-model="cotizar" />
            </div>
            <table class="field col-12 md:col-12">
              <tr>
                <td>
                  <strong>RECIBIDO:</strong>
                </td>
                <td class="text-right">
                  <InputNumber
                    v-model="efectivo"
                    :min="0"
                    locale="de-DE"
                    input-class="text-right"
                    :minFractionDigits="2"
                    inputId="locale-german"
                    :disabled="
                      metodo_pagoSelect != 1
                        ? true
                        : false || a_credito_check == 'a_credito_check'
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    <h2>CAMBIO:</h2>
                  </strong>
                </td>
                <td class="text-right p-invalid">
                  <strong>
                    <h1>{{ convertirNumeroGermanicFormat(cambio) }} Bs.</h1>
                  </strong>
                </td>
              </tr>
            </table>
            <div class="col-12 md:col-12 flex justify-content-end">
              <Button
                :label="proforma ? 'GUARDAR PROFORMA' : 'REGISTRAR VENTA'"
                icon="pi pi-save"
                v-tooltip.top="
                  proforma ? 'Registrar Proforma' : 'Registrar Venta'
                "
                class="p-button-primary p-button-lg"
                :loading="guardando"
                @click="guardarVenta"
                :disabled="total_venta == 0 || guardando ? true : false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-else>
      <Message class="col-12" :severity="'info'" :closable="false">
        <div class="flex align-items-center justify-content-center">
          <span> PARA REALIZAR VENTAS DEBE ABRIR LA CAJA </span>
          <Button
            label="APERTURAR CAJA"
            class="p-button-success p-button-lg ml-3"
            icon="pi pi-lock-open"
            v-tooltip.top="'Abrir Caja'"
            @click="this.$router.push({ name: 'caja' })"
          />
        </div>
      </Message>
    </div>
  </div>
  <div v-else>
    <div class="grid">
      <h3 class="col-12">Verificando caja ...</h3>
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        class="col-12"
      />
    </div>
  </div>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="cargarClientesListado"
  >
  </ClienteCreate>
  <MedicoCreate
    :show="medicoModal"
    :medico="medico"
    @closeModal="cerraModalMedico"
    @actualizarListado="cargarMedicosListado"
  >
  </MedicoCreate>

  <ProducoListadoModal
    @closeModal="cerrarModalProductoResultados"
    :show="productosResultados"
    :productos="productosFiltrados"
    @agregarProductos="agregarProductos"
  ></ProducoListadoModal>
</template>

<script>
import { has } from "lodash";
import { useAuth } from "@/stores";
import CajaService from "@/service/CajaService";
import GrupoService from "@/service/GrupoService";
import VentaService from "@/service/VentaService";
import MedicoService from "@/service/MedicoService";
import FacturaService from "@/service/FacturaService";
import ClienteService from "@/service/ClienteService";
import ProductService from "@/service/ProductService";
import TipoPagoService from "@/service/TipoPagoService";
import MetodoPagoService from "@/service/MetodoPagoService";
import MedicoCreate from "@/module/medicos/MedicoCreate.vue";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import PaqueteProductoService from "@/service/PaqueteProductoService";
import ParametrosSistemaService from "@/service/ParametrosSistemaService";
import ProducoListadoModal from "@/module/productos/ProductoListadoModal.vue";
import EmpresaService from "@/service/EmpresaService";

export default {
  components: {
    ClienteCreate,
    ProducoListadoModal,
    MedicoCreate,
  },
  data() {
    return {
      nro_dias_proforma: 30,
      proforma: false,
      masFiltrosBusqueda: false,
      /* busqueda_caso_uso: false, */
      permitir_facturar: false,
      grupos: [],
      /* procedencias: [], */
      grupoSelected: null,
      /*  procedenciaSelected: null, */
      facturar: false,
      cotizar: false,
      guardando: false,
      clienteSelect: null,
      clientes: [],
      razon_social: null,
      nit: 0,
      productoSelecionado: null,
      productosSelecionado: null,
      productosListadoFiltrado: [],
      productosVentas: [],
      stockSucursales: [],
      subtotal: 0,
      descuento: 0,
      total_venta: 0,
      efectivo: 0,
      monto_pago: 0, //monto de pago en efectivo
      clienteModal: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      medicoModal: false,
      medico: { estado: { label: "Activo", value: 1 } },
      fecha_venta: null,
      permitirVenta: false,
      cargandoComponente: false,
      sucursal_id: null,
      caja: null,
      codProdSelected: null,
      codProdListadoFiltrado: [],
      desc_venta_porcentaje: 0,
      aplica_descuento: false,
      tipo_pagos: [],
      tipo_pagoSelect: 1,
      metodo_pagos: [],
      metodo_pagoSelect: 1,
      dias_credito: 0,
      a_cuenta: 0,
      saldo: 0,
      observacion: "",
      observacion_check: false,
      a_credito_check: false,
      metodo_pago_check: false,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      vender_paquete: false,
      paqueteSelected: null,
      paqueteproductos: [],
      paqueteproductosListado: [],
      entregado: 0,
      medico_referencia: false,
      medicos: [],
      medicoSelected: null,
      productosResultados: false,
      productosFiltrados: [],
      filtrosRadio: null,
      grupos_id_selected: null,
      actividades_economicas: [],
      actividad_economica_selected: null,
      laboratorios: [],
      laboratorioSelected: null,
    };
  },
  auth: null,
  cajaService: null,
  ventaService: null,
  grupoService: null,
  medicoService: null,
  facturaService: null,
  productService: null,
  clienteService: null,
  tipoPagoService: null,
  metodoPagoService: null,
  paqueteProductoService: null,
  parametrosSistemaService: null,
  empresaService: null,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    this.productService = new ProductService();
    this.ventaService = new VentaService();
    this.cajaService = new CajaService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.paqueteProductoService = new PaqueteProductoService();
    this.medicoService = new MedicoService();
    this.facturaService = new FacturaService();
    this.parametrosSistemaService = new ParametrosSistemaService();
    this.grupoService = new GrupoService();
    this.empresaService = new EmpresaService();
  },
  mounted() {
    this.datos_venta_general();
    /* this.verificarCajaAbierta(); */
    /* this.cargarClientesListado(); */
    /* this.obtenerTiposPagos(); */
    /* this.obtenerMetodosPagos(); */
    /* this.obtenerPaquetes(); */
    /* this.obtenerMedicos(); */
    /* this.verificarFacturar(); */
  },
  computed: {
    // si mi venta es al credito entonces en efectivo se registra el monto  a cuenta el cambio es el total efectivo - a cuenta
    cambio() {
      if (this.tipo_pagoSelect == 2) {
        return this.efectivo - this.a_cuenta;
      } else {
        return this.efectivo + this.monto_pago - this.total_venta;
      }
    },
  },
  methods: {
    agregarProductoDinamico() {
      this.productosVentas.push({
        nombre_producto: "Producto Editable",
        cantidad_venta: 1,
        descuento_neto: 0,
        descuento_porcentaje: 0,
        ultimo_precio: 0,
        total: 0,
        cantidad: 999,
        producto_dinamico: true,
        nro_dias_importacion: 30,
      });
    },
    cambiarActividadEconomica() {
      let datos = {
        actividad_economica_id: this.actividad_economica_selected,
      };
      this.empresaService.changeActividad(datos).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "ACTIVIDAD ECONOMICA",
            detail: data.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error al cambiar la actividad economica",
            life: 3000,
          });
        }
      });
    },
    calcular_agregar_des_total_venta() {
      this.calcular_descuento_venta_porcentaje();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    calcular_agregar_des_total_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    actualizarPanelMasFiltros(e) {
      this.masFiltrosBusqueda = e;
      if (!this.masFiltrosBusqueda) {
        this.limpiarFiltrosBusquedaProducto();
      }
    },
    confirmarVenta() {
      this.$confirm.require({
        target: event.confirmarVenta,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$toast.add({
            severity: "success",
            summary: "Confirmado",
            detail: "Se realizo la venta",
            life: 3000,
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Rechazado",
            detail: "Se cancelo la venta",
            life: 3000,
          });
        },
      });
    },
    cerrarModalProductoResultados() {
      this.productosResultados = false;
    },
    activarModalProductosFiltrados() {
      this.productosResultados = true;
      this.productosFiltrados = this.productosListadoFiltrado;
    },
    datos_venta_general() {
      let datos = {
        verificar_caja: true,
        facturar: "facturar",
      };
      this.ventaService.VentaGeneral(datos).then((response) => {
        if (response.success) {
          if (response.datos.caja.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se encontró una caja abierta",
            });
            this.permitirVenta = false;
            this.cargandoComponente = true;

            return;
          }

          this.caja = response.datos.caja[0];
          this.sucursal_id = response.datos.caja[0].sucursal_id;
          this.permitirVenta = true;

          this.clientes = response.datos.clientes;
          this.clienteSelect = this.clientes.length ? this.clientes[0] : null;
          this.tipo_pagos = response.datos.tipo_pagos;
          this.metodo_pagos = response.datos.metodo_pagos;
          this.paqueteproductos = response.datos.paquetes;
          this.medicos = response.datos.medicos;
          this.grupos = response.datos.grupos;
          this.procedencias = response.datos.procedencias;
          if (response.datos.facturar) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
          this.actividades_economicas =
            response.datos.actividades_economicas || [];
          if (this.actividades_economicas.length) {
            this.actividad_economica_selected =
              response.datos.actividad_economica_select.codigo;
          }
          this.laboratorios = response.datos.laboratorios || [];
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
        }

        this.cargandoComponente = true;
      });
    },
    verificarFacturar() {
      this.parametrosSistemaService
        .verificarPermiso("facturar")
        .then((response) => {
          if (response.permiso) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
        });
    },
    obtenerMedicos() {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
      });
    },
    obtenerPaquetes() {
      this.paqueteProductoService.getPaqueteProductoAll().then((response) => {
        this.paqueteproductos = response.paqueteproductos;
      });
    },
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response.tipo_pagos;
      });
    },
    obtenerMetodosPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    buscarClientePorNit() {
      //BUSCO EL CLIENTE POR NIT Y SI NO EXISTE LO CREO
      if (this.nit != null) {
        this.clienteService
          .buscarClientePorNit({ nit: this.nit })
          .then((response) => {
            if (response.cliente != null) {
              this.$toast.add({
                severity: "success",
                summary: "Nit encontrado",
                detail: "Se cargaron los datos del cliente",
                life: 3000,
              });
              /* this.razon_social = response.cliente.nombre_cliente; */
              let nombre_cliente =
                response.cliente.nombre_cliente ?? response.cliente;
              this.razon_social = nombre_cliente ?? "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Cliente no encontrado",
                detail: "Registre los datos del cliente",
                life: 3000,
              });
              /* this.cliente.razon_social = this.razon_social;
              this.cliente.nit = this.nit;
              this.cliente_id = this.clienteSelect.id; */
              this.razon_social = "";
            }
          });
      }
    },

    aplicar_descuento_productos_detalle() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_porcentaje = this.desc_venta_porcentaje;
        producto.descuento_neto =
          producto.ultimo_precio *
          producto.cantidad_venta *
          (producto.descuento_porcentaje / 100);
      });
      this.calcular_subtotal();
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.clientes = [...response.clientes];
          });
      }, 250);
    },
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirVenta = true;
        } else {
          this.permitirVenta = false;
        }

        this.cargandoComponente = true;
      });
    },

    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
        maximumIntegerDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numero);
    },

    verificarMedico() {
      if (this.medico_referencia) {
        if (this.medicoSelected == "") {
          this.$toast.add({
            severity: "error",
            summary: "Medico",
            detail: "Debe seleccionar un medico",
            life: 3000,
          });
          this.guardando = false;
          return;
        }
      }

      return true;
    },

    guardarVenta() {
      /* this.guardando = true; */
      // verifico si en la tabla de productos hay algun producto con cantidad de venta en 0 o null
      let productos = this.productosVentas.filter(
        (producto) =>
          producto.cantidad_venta == 0 || producto.cantidad_venta == null
      );
      if (productos.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: "Revisar productos con cantidad de venta en 0",
          detail:
            "No puede guardar una venta con productos con cantidad de venta en 0",
          life: 3000,
        });
        this.guardando = false;
        return;
      }
      if (!this.verificarMedico()) {
        this.guardando = false;
        return;
      }

      let datos = {
        razon_social: this.razon_social,
        nit: this.nit,
        cliente_id: this.clienteSelect.id,
        total_cancelar: this.total_venta,
        descuento: this.descuento,
        efectivo: this.efectivo,
        productos: this.productosVentas,
        caja_id: this.caja.id,
        tipo_pago_id: this.tipo_pagoSelect,
        metodo_pago_id: this.metodo_pagoSelect,
        monto_pago: this.monto_pago,
        dias_credito: this.dias_credito,
        a_cuenta: this.a_cuenta,
        cambio: this.cambio,
        facturado: this.facturar ? 1 : 0,
        observacion: this.observacion,
        nombre_paquete: this.paqueteSelected ? this.paqueteSelected : null,
        proforma: this.proforma,
        actividad_economica_selected: this.actividad_economica_selected,
        nro_dias_proforma: this.nro_dias_proforma,
      };

      if (this.medico_referencia == "medico_referencia") {
        datos = { ...datos, medico_id: this.medicoSelected };
      }

      //fecha_venta: this.fecha_venta,

      let texto_mensaje_confirmacion = this.proforma
        ? "¿ESTÁS SEGURO DE GUARDAR LA PROFORMA?"
        : "¿ESTÁS SEGURO DE GUARDAR LA VENTA?";

      if (this.productosVentas.length > 0) {
        this.$confirm.require({
          header: "CONFIRMACIÓN",
          message: texto_mensaje_confirmacion,
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Si, Guardar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          closable: false,
          closeOnEscape: false,
          accept: () => {
            this.guardando = true;
            this.ventaService
              .crear(datos)
              .then((res) => {
                if (has(res, "proforma")) {
                  this.$toast.add({
                    severity: "success",
                    summary: "Venta",
                    detail: "Proforma Realizada con Exito",
                    life: 3000,
                  });
                  this.guardando = false;
                  this.$router.push({
                    name: "proforma_detalle",
                    params: { id: res.proforma.id },
                  });
                  return;
                }
                if (res.status == 400 || res.mensaje) {
                  this.guardando = false;
                  this.$toast.add({
                    severity: "info",
                    summary: "Venta",
                    detail: res.mensaje,
                    life: 8000,
                  });
                  return;
                }
                this.$toast.add({
                  severity: "success",
                  summary: "Venta",
                  detail: "Venta Realizada con Exito",
                  life: 3000,
                });
                this.guardando = true;
                this.$router.push({
                  name: "venta_detalle",
                  params: { id: res.venta.id },
                });
                /* this.ventaService.imprimirVenta(res.venta.id); */
              })
              .catch(() => {
                this.$toast.add({
                  severity: "error",
                  summary: "Venta",
                  detail: "Error de conexion",
                  life: 3000,
                });
                this.guardando = false;
              });
          },
          reject: () => {
            this.$toast.add({
              severity: "info",
              summary: "Venta",
              detail: "Venta Cancelada",
              life: 3000,
            });
            this.guardando = false;
          },
        });
        //ejecuto
        /* this.ventaService
          .crear(datos)
          .then((res) => {
            if (res.status == 400 || res.mensaje) {
              this.guardando = false;
              this.$toast.add({
                severity: "info",
                summary: "Venta",
                detail: res.mensaje,
                life: 8000,
              });
              return;
            }

            this.$toast.add({
              severity: "success",
              summary: "Venta",
              detail: "Venta Realizada con Exito",
              life: 3000,
            });
            this.guardando = false;
            this.$router.push({
              name: "venta_detalle",
              params: { id: res.venta.id },
            });
            this.ventaService.imprimirVenta(res.venta.id);
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Venta",
              detail: "Error de conexion",
              life: 3000,
            });
            this.guardando = false;
          });
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "No hay productos en la venta",
          life: 3000,
        });
        this.guardando = false;
      } */
      }
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarMedico() {
      this.medicoModal = true;
      this.medico = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerraModalMedico() {
      this.medicoModal = false;
      this.medico = {};
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      /*  if (field == "cantidad_venta") {
        if (data.cantidad_venta > data.cantidad) {
          data.cantidad_venta = parseInt(data.cantidad);
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "No hay suficiente stock",
            life: 3000,
          });
        }
      } */

      if (field == "cantidad_venta") {
        if (data.cantidad_venta > data.cantidad) {
          /* data.cantidad_venta = parseInt(data.cantidad == 0); */
          //hago que mi cantidad se ponga en null
          data.cantidad_venta = null;
          this.$toast.add({
            severity: "error",
            summary: "Revisar Stock de Producto",
            detail: "No hay suficiente stock: " + data.nombre_producto,
            life: 9000,
          });
        } else {
          data["total"] = data["cantidad_venta"] * data["ultimo_precio"];
          this.calcular_subtotal();
          /*  this.calcular_descuentos_totales_venta(); */
        }

        /*  data["total"] = newValue * data["ultimo_precio"];
        this.calcular_subtotal(); */
      }
      if (field == "descuento_neto" && data["cantidad_venta"] > 0) {
        parseFloat(
          (data["descuento_porcentaje"] =
            (newValue * 100) / data["total"]).toFixed(2)
        );
        this.calcular_descuentos_totales_venta();
      }
      if (field == "descuento_porcentaje" && data["cantidad_venta"] > 0) {
        data["descuento_neto"] = (newValue * data["total"]) / 100;
        this.calcular_descuentos_totales_venta();
      }

      if (field == "ultimo_precio") {
        data["total"] = data["cantidad_venta"] * data["ultimo_precio"];

        this.calcular_subtotal();
      }
    },
    calcular_descuentos_totales_venta() {
      let desc_venta_neto = 0;
      /* let desc_venta_porcentaje = 0; */
      this.productosVentas.forEach((producto) => {
        desc_venta_neto += parseFloat(producto.descuento_neto);
        /* desc_venta_porcentaje += parseFloat(producto.descuento_porcentaje); */
      });
      this.descuento = desc_venta_neto;
      this.desc_venta_porcentaje = (this.descuento * 100) / this.subtotal;
      this.calcular_total_venta();
    },
    calcular_subtotal() {
      this.subtotal = 0;
      this.productosVentas.forEach((product) => {
        this.subtotal += product.total;
      });
      this.total_venta = this.subtotal - this.descuento;
    },
    agregar_descuento_detalle_productosventas() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_neto =
          parseFloat(this.descuento * producto.total) / this.subtotal;
        producto.descuento_porcentaje =
          parseFloat(producto.descuento_neto * 100) / producto.total;
      });
    },
    calcular_total_venta() {
      this.total_venta = this.subtotal - this.descuento;
    },
    calcular_descuento_venta_porcentaje() {
      this.desc_venta_porcentaje = parseFloat(
        ((this.descuento * 100) / this.subtotal).toFixed(2)
      );
    },
    calcular_descuento_venta_neto() {
      this.descuento = /* Math.round */ parseFloat(
        ((this.desc_venta_porcentaje * this.subtotal) / 100).toFixed(2)
      );
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: "¿ESTÁS SEGURO DE ELIMINAR EL PRODUCTO?",
        acceptLabel: "Si, Eliminar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.productosVentas.splice(datos.index, 1);
          this.calcular_subtotal();
          this.calcular_descuentos_totales_venta();
        },
      });
    },
    buscarCodProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXCodProducto({ texto: event.query })
          .then((response) => {
            if (response.productos.length == 0) {
              this.$toast.add({
                severity: "info",
                summary: "No se encontraron productos",
                detail: "No se encontraron productos",
                life: 3000,
              });
            }
            this.codProdListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    limpiarFiltrosBusquedaProducto() {
      this.grupoSelected = null;
      /* this.procedenciaSelected = null; */
      this.filtrosRadio = null;
      this.masFiltrosBusqueda = false;
      this.laboratorioSelected = null;
    },
    buscarProducto(/* event */) {
      let _text = this.productoSelecionado; //event.query ?? event.value;

      let datos_busqueda = {
        texto: _text,
        /*  busqueda_caso_uso: this.busqueda_caso_uso, */
        filtros: !this.masFiltrosBusqueda,
        sucursal_id: this.sucursal_id,
      };

      if (!this.masFiltrosBusqueda) {
        datos_busqueda.grupo_id = this.grupoSelected;
        /* datos_busqueda.procedencia_id = this.procedenciaSelected; */
        datos_busqueda.columna_busqueda = this.filtrosRadio;
        datos_busqueda.laboratorio_id = this.laboratorioSelected;
      }

      this.productService
        .buscarXNombreVenta(datos_busqueda)
        .then((response) => {
          if (response.productos.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "BUSQUEDA DE PRODUCTOS",
              detail: "No se encontraron productos",
              life: 3000,
            });
          }
          this.productosListadoFiltrado = [...response.productos];
          //que me levante el modal activarModalProductosFiltrados
          if (this.productosListadoFiltrado.length > 0) {
            this.activarModalProductosFiltrados();
          }
        })
        .catch((error) => console.log(error));
    },
    cargarClientesListado(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          this.clientes = response;
          // recorro los cliente y crgo al select el menor id
          this.clientes.forEach((cliente) => {
            // busco el cliente CONTROL TRIBUTARIO 99002 y lo cargo al select por defecto al cargar la pagina
            if (cliente.id == 1 && selected == 1) {
              this.clienteSelect = cliente;
            } else if (selected != null && cliente.id == selected.id) {
              this.clienteSelect = cliente;
              this.ci_nit = cliente.ci_nit;
              this.nombre_cliente = cliente.nombre;
            }
          });
        })
        .catch((error) => console.log(error));
    },
    cargarMedicosListado(medico = null) {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
        if (medico) {
          this.medicos.forEach((element) => {
            if (element.id == medico.id) {
              this.medicoSelected = element.id;
            }
          });
        }
      });
    },
    agregarDetalleVenta(data) {
      if (this.productosVentas.length > 0) {
        let existe = this.productosVentas.find(
          (producto) => producto.id == data.id
        );
        if (existe) {
          this.$toast.add({
            severity: "info",
            summary: "VENTA/PRODUCTO",
            detail:
              "El producto: " +
              data.nombre_producto +
              " ya se encuentra en la venta",
            life: 10000,
          });
          return;
        }
      }
      if (data.precio_compra == 0 || data.precio_compra == null) {
        this.$toast.add({
          severity: "info",
          summary: "VENTA/PRECIO COMPRA",
          detail:
            "El producto no tiene un precio de compra asignado: " +
            data.nombre_producto,
          life: 10000,
        });
        //sin precio de compra no se puede vender
        return;
      }
      // verifico si el producto no tiene stock para no agregarlo al detalle
      if (data.cantidad == 0) {
        this.$toast.add({
          severity: "info",
          summary: "VENTA/STOCK",
          detail: "El producto: " + data.nombre_producto + " no tiene stock",
          life: 10000,
        });
        return;
      }
      if (data.ultimo_precio == 0 || data.precio_sugerido == 0) {
        this.$toast.add({
          severity: "info",
          summary: "VENTA/PRECIOS",
          detail:
            "El producto no tiene un precio de Venta asignado: " +
            data.nombre_producto,
          life: 10000,
        });
        return;
      }
      let precio =
        data.ultimo_precio == 0 ? data.precio_sugerido : data.ultimo_precio;
      this.productosVentas.push({
        cantidad_venta: 1,
        descuento_neto: 0,
        descuento_porcentaje: 0,
        ...data,
        ultimo_precio: precio,
        total: precio * data.cantidad_venta,
      });

      this.stockSucursales = [];
      this.productosListadoFiltrado = [];
      this.productoSelecionado = null;

      this.calcular_subtotal();
    },
    agregarProductos(productosSeleccionados) {
      productosSeleccionados.forEach((producto) => {
        try {
          if (
            producto.stock == null ||
            producto.stock == "" ||
            producto.stock == 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "VENTA/STOCK",
              detail:
                "El producto: " + producto.descripcion + " no tiene stock",
              life: 10000,
            });
            return;
          }
          this.agregarDetalleVenta({
            ...producto,
            cantidad: producto.stock[0].cantidad,
            producto_id: producto.id,
            sucursal_id: producto.stock[0].sucursal_id,
            nombre_producto: producto.descripcion,
            cantidad_venta: 1,
            descuento_neto: 0,
            descuento_porcentaje: 0,
            ultimo_precio: parseFloat(producto.precio_sugerido),
            precio_sugerido: parseFloat(producto.precio_sugerido),
            total: parseFloat(producto.precio_sugerido * 1),
          });
        } catch (error) {
          console.log(error);
        }
      });
      this.cerrarModalProductoResultados();
    },
    agregarProductosPaqueteAlDetalle() {
      let datos = {
        paquete_producto_id: this.paqueteSelected,
      };
      this.paqueteProductoService
        .getPaqueteProductoById(datos)
        .then((response) => {
          /* this.paqueteSelected = null; */
          response.paqueteproductosListado.forEach((producto) => {
            let paquete_cantidad = producto.cantidad;
            let precio =
              producto.stock_para_caja_activa == null
                ? producto.producto.precio_sugerido
                : producto.stock_para_caja_activa.ultimo_precio;
            //comparo la cantidad del stock con la cantidad del paquete
            if (producto.stock_para_caja_activa.cantidad < paquete_cantidad) {
              this.$toast.add({
                severity: "error",
                summary: "Revisar stock del producto",
                detail:
                  "El producto no tiene stock suficiente: " +
                  producto.producto.descripcion,
                life: 120000,
              });
              return;
            }
            this.agregarDetalleVenta({
              ...producto,
              id: producto.producto_id,
              cantidad: producto.stock_actual,
              sucursal_id: producto.sucursal_id,
              cantidad_venta: paquete_cantidad,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              ultimo_precio: parseFloat(precio),
              precio_sugerido: parseFloat(producto.producto.precio_sugerido),
              total: parseFloat(precio * producto.cantidad),
            });
            /* this.productosVentas.push(); */

            this.calcular_subtotal();
          });
        })
        .catch((error) => console.log(error));
    },
    fechaActual() {
      return new Date().toLocaleDateString();
    },
  },
  watch: {
    proforma() {
      if (this.proforma == false) {
        this.productosVentas = [];
      }
    },
    clienteSelect(datos) {
      if (this.facturar == true) {
        this.nit = datos.ci_nit;
        this.razon_social = datos.nombre;
        this.grupos_id_selected =
          datos.grupos_id_selected != null
            ? datos.grupos_id_selected.join(",\n")
            : null;
        // si es factura
      } else {
        this.nit = datos.ci_nit;
        this.razon_social = datos.nombre;
        this.grupos_id_selected =
          datos.grupos_id_selected != null
            ? datos.grupos_id_selected.join(",\n")
            : null;
      }
    },

    productoSelecionado(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
          });
        }
      }
    },
    codProdSelected(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
            this.codProdSelected = null;
          });
        }
      }
    },
    /* descuento() {
      this.calcular_total_venta();
      this.calcular_descuento_venta_porcentaje();
      this.agregar_descuento_detalle_productosventas();
    },
    desc_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
    }, */
    total_venta() {
      if (this.tipo_pagoSelect == 1) {
        this.efectivo = this.total_venta;
      } else {
        this.a_cuenta = 0;
        this.efectivo = 0;
      }
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
      } else {
        this.monto_pago = this.total_venta;
      }
    },
    a_cuenta() {
      if (this.a_cuenta > this.total_venta) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El monto a cuenta no puede ser mayor al total de la venta",
          life: 3000,
        });
        this.a_cuenta = 0;
        return;
      }
      this.efectivo = this.a_cuenta;
    },
    monto_pago() {
      if (this.tipo_pagoSelect == 2) {
        this.efectivo = this.a_cuenta;
      } else {
        this.efectivo = this.total_venta - this.monto_pago;
      }
    },
    tipo_pagoSelect() {
      if (this.tipo_pagoSelect == 1) {
        this.a_cuenta = 0;
        this.monto_pago = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
        this.a_credito_check = "";
      } else {
        this.dias_credito = 30;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      }
    },
    metodo_pagoSelect() {
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
        this.metodo_pago_check = "";
      } else {
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      }
    },
    metodo_pago_check() {
      if (this.metodo_pago_check == "metodo_pago_check") {
        this.metodo_pagoSelect = 2;
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      } else {
        this.metodo_pagoSelect = 1;
        this.metodo_pago_check = "";
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
      }
    },
    a_credito_check() {
      if (this.a_credito_check == "a_credito_check") {
        this.tipo_pagoSelect = 2;
        this.dias_credito = 30;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      } else {
        this.tipo_pagoSelect = 1;
        this.a_credito_check = "";
        this.a_cuenta = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
      }
    },
    /* aplica_descuento() {
      if (this.aplica_descuento == "aplica_descuento") {
        this.desc_venta_porcentaje = 5;
        Math.round(this.descuento * 100) / 100;
      } else {
        this.desc_venta_porcentaje = 0;
      }
    }, */
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
}
.precio {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
